<template>
  <div class="about">
    <a class="section-vision" :href="link" target="_blank"> </a>
    <div class="section-part1 row-center">
      <div class="section-box part1">
        <div>
          <div class="part1-head">
            <div class="icon"></div>
            <h1 class="title">
              <strong>关于我们</strong>
            </h1>
          </div>
          <div class="part1-body">
            <p v-for="(item, index) in part1" :key="`part1${index}`">{{ item }}</p>
          </div>
        </div>
        <div class="part1-foot">
          <img src="@/assets/about/part1-1.jpg" alt="" />
          <img src="@/assets/about/part1-3.jpg" alt="" />
          <img src="@/assets/about/part1-2.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="section-part2 row-center">
      <div class="section-box part2">
        <div class="part2-body">
          <div class="item" v-for="(item, index) in part2" :key="index">
            <div class="item-head">
              <img :src="item.poster" alt="" />
            </div>
            <div class="item-body">
              <h1>{{ item.title }}</h1>
              <ul>
                <li v-for="(itemJ, indexJ) in item.content" :key="indexJ">
                  <strong>{{ itemJ.label }}</strong>
                  <span>{{ itemJ.value }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-part3 row-center">
      <div class="section-box part3">
        <div style="flex:1 1 auto">
          <div class="part3-head">
            <div class="icon"></div>
            <h1 class="title">
              <strong>企业文化</strong>
            </h1>
          </div>
          <div class="part3-body">
            <div class="item" v-for="(item, index) in part3" :key="`part3${index}`">
              <strong>{{ item.label }}</strong>
              <span>{{ item.value }}</span>
            </div>
          </div>
        </div>
        <div class="part3-foot">
          <img src="@/assets/about/part3-1.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="section-part4 row-center">
      <div class="section-box part4">
        <div style="flex:1 1 auto">
          <div class="part4-head">
            <div class="icon"></div>
            <h1 class="title">
              <strong>全方位贴心福利<br />你的生活</strong>
            </h1>
          </div>
          <div class="part4-body">
            <div class="item" v-for="(item, index) in part4" :key="`part3${index}`">
              <strong>{{ item.label }}</strong>
              <span>{{ item.value }}</span>
            </div>
          </div>
        </div>
        <div class="part4-foot">
          <img src="@/assets/about/part4-1.jpg" alt="" />
          <img src="@/assets/about/part4-2.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="section-part5 row-center">
      <div class="section-box part5">
        <div style="flex:1 1 auto">
          <div class="part5-head">
            <div class="icon"></div>
            <h1 class="title">
              <strong>加入我们 热忱的你 期待相遇</strong>
            </h1>
          </div>
          <div class="part5-body">
            <a class="link" :href="link" target="_blank">点击查看热招岗位</a>
            <p>欢迎来投</p>
            <a class="mail" href="mailto:hr@xianweiyeye.com">hr@xianweiyeye.com</a>
          </div>
        </div>
        <div class="part5-foot">
          <img src="@/assets/about/part5-1.jpg" alt="" />
          <a class="link" :href="link" target="_blank">点击查看热招岗位</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      part1: [
        "仙味爷爷，成立于2020年，是国内原创地域食品潮牌，以专注潮汕特色风味而迅速出圈。",
        "创始人陈湘，是一名连续创业者，也是一名“被创业耽误的厨神”。生长于潮汕，行走于世界之间。从小便对美食有着敏锐的味觉和痴迷的研究。留学期间，他亲自下厨，将家乡味分享给同学朋友，收获交口称赞。",
        "一场疫情，让朋友们更想念这份味道。陈湘心生一念，制作潮汕美食寄给大家，越来越多人被圈粉，也被潮汕味道治愈并鼓舞着。他开始思考，如何让更多人了解并爱上潮汕这一味。",
        "潮汕美食历尽岁月凝练，传承于旧时个人作坊和手工厨技，却也因此受困于美食孤岛。传统美食需要开花，传统文化需要继承。陈湘执着深耕，坚定“潮汕精髓.品质鲜美.高级味道.材真料足”为理念，致力于让中华高级料理走进千家万户。潮汕传统风味与年轻一代的结合不容忽视，团队发散国际视野，借力新兴科技，迎合新消费大势所趋，仙味爷爷应运而生。",
        "传统在，便有记忆可承载。那些沉淀下来的潮汕味道精髓，将和仙味爷爷一起从创新启程，以传统回归，在全球开花结果。"
      ],
      part2: [
        {
          title: "仙味智造中心·专属柔性供应链",
          content: [
            {
              label: "30年专业",
              value: "与30年潮汕食品制造专家共建"
            },
            {
              label: "柔性供应链",
              value: "快速响应新品类，兼具传统与创新"
            },
            {
              label: "产能可控",
              value: "酱料类每日产能可达3-6万瓶"
            },
            {
              label: "严格品控",
              value: "CCP点严格把控+出口体系认证"
            }
          ],
          poster: require("@/assets/about/part2-1.jpg")
        },
        {
          title: "仙味食验室",
          content: [
            {
              label: "研发中心",
              value: "与广东省食品协会潮州市厨师协会等组织深耕研发更多潮州名厨与民间美食"
            },
            { label: "透明制作", value: "公开透明化烹饪制作流程，让大家吃得放心，吃得更好更美味" },
            { label: "美食社区", value: "增加研发选品渠道,请用户与口味官测试产品" }
          ],
          poster: require("@/assets/about/part2-2.jpg")
        }
      ],
      part3: [
        {
          label: "简单正直",
          value: "为人坦率真诚，自律正直"
        },
        {
          label: "热爱生活",
          value: "对生活充满希冀、热爱美食、享受美食"
        },
        {
          label: "创新卓越",
          value: "突破自我，以不断求变的创新精神，追求卓越"
        },
        {
          label: "使命必达",
          value: "富有责任心，坚定信念，完成使命"
        }
      ],
      part4: [
        {
          label: "办公环境",
          value: "网红创意园区，临近公交地铁，交通便利"
        },
        {
          label: "福利待遇",
          value: "入职即买五险一金，吃饱喝足，提供福利午餐"
        },
        {
          label: "员工关怀",
          value: "开心工作，不定期旅游及休闲活动等"
        }
      ],
      link:
        "https://www.zhipin.com/gongsi/a54a213c625033111nZ-09m_FVs~.html?ka=search_rcmd_company_name_a54a213c625033111nZ-09m_FVs~_custompage"
    };
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .about {
    .section-vision {
      background: url("../../assets/about/vision-m.jpg") no-repeat center center;
      background-size: cover;
      width: 100%;
      height: 4rem;
    }
    .section-part1 {
      background: #fff;
      padding: 0.6rem 0.6rem 0.4rem;
      box-sizing: border-box;
      .part1 {
        display: flex;
        flex-direction: column;
        &-head {
          margin-bottom: 0.15rem;
          .icon {
            display: none;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 0.36rem;
              color: #e76d01;
              line-height: 0.5rem;
            }
          }
        }
        &-body {
          & > p {
            font-family: PingFangSC-Light;
            font-size: 0.26rem;
            color: #333333;
            line-height: 0.4rem;
            text-align: left;
            &:not(:last-child) {
              margin-bottom: 0.5rem;
            }
          }
        }
        &-foot {
          width: 100%;
          flex: 0 0 auto;
          display: flex;
          flex-direction: column;
          & > img {
            width: 100%;
            border-radius: 0.06rem;
            &:not(:last-child) {
              margin-bottom: 0.2rem;
            }
          }
        }
      }
    }
    .section-part2 {
      padding: 0.4rem 0.6rem;
      box-sizing: border-box;
      .part2 {
        &-body {
          display: flex;
          flex-direction: column;
          .item {
            width: 100%;
            display: flex;
            flex-direction: column;
            &:not(:last-child) {
              margin-bottom: 0.47rem;
            }
            &-head {
              flex: 0 0 auto;
              width: 100%;
              height: 3rem;
              border-radius: 0.06rem;
              overflow: hidden;
              margin-bottom: 0.3rem;
              & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            &-body {
              display: flex;
              flex-direction: column;
              & > h1 {
                font-size: 0.32rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #e76d01;
                line-height: 0.45rem;
                margin-bottom: 0.22rem;
                text-align: left;
              }
              & > ul {
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 0;
                margin: 0;
                & > li {
                  :not(:last-child) {
                    margin-bottom: 0.12rem;
                  }
                  display: flex;
                  & > strong {
                    flex: 0 0 auto;
                    width: 1.6rem;
                    font-size: 0.26rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 0.4rem;
                    text-align: left;
                  }
                  & > span {
                    font-size: 0.24rem;
                    font-family: PingFangSC-Light, PingFang SC;
                    font-weight: 300;
                    color: #333333;
                    line-height: 0.4rem;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
    .section-part3 {
      background: #fff;
      padding: 0.84rem 0.6rem 0.4rem;
      box-sizing: border-box;
      .part3 {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        &-head {
          margin-bottom: 0.38rem;
          .icon {
            display: none;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 0.32rem;
              color: #e76d01;
              line-height: 0.45rem;
              text-align: left;
            }
          }
        }
        &-body {
          display: flex;
          flex-direction: column;
          .item {
            display: flex;
            flex-direction: column;
            &:not(:last-child) {
              margin-bottom: 0.2rem;
            }
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 0.3rem;
              color: #333333;
              line-height: 0.48rem;
              text-align: left;
            }
            & > span {
              display: none;
              font-family: PingFangSC-Light;
              font-size: 20px;
              color: #333333;
              line-height: 40px;
              text-align: left;
            }
          }
        }
        &-foot {
          flex: 0 0 auto;
          width: 3.37rem;
          height: 4.34rem;
          margin-right: 0.76rem;
          border-radius: 0.06rem;
          overflow: hidden;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .section-part4 {
      background: #fff;
      padding: 0.4rem 0.6rem 0.5rem;
      box-sizing: border-box;
      .part4 {
        display: flex;
        flex-direction: column;
        &-head {
          margin-bottom: 0.2rem;
          .icon {
            display: none;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 0.32rem;
              color: #e76d01;
              line-height: 0.45rem;
              text-align: left;
            }
          }
        }
        &-body {
          display: flex;
          flex-direction: column;
          margin-bottom: 0.5rem;
          .item {
            display: flex;
            flex-direction: column;
            &:not(:last-child) {
              margin-bottom: 0.14rem;
            }
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 0.3rem;
              color: #333333;
              line-height: 0.42rem;
              text-align: left;
              margin-bottom: 0.04rem;
            }
            & > span {
              font-family: PingFangSC-Light;
              font-size: 0.26rem;
              color: #333333;
              line-height: 0.48rem;
              text-align: left;
            }
          }
        }
        &-foot {
          flex: 0 0 auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          & > img {
            width: 100%;
            border-radius: 0.06rem;
            &:not(:last-child) {
              margin-bottom: 0.16rem;
            }
          }
        }
      }
    }
    .section-part5 {
      padding: 0.8rem 0.6rem;
      box-sizing: border-box;
      .part5 {
        display: flex;
        flex-direction: column;
        &-head {
          margin-bottom: 0.3rem;
          .icon {
            background: url("../../assets/about/part5-0.png") no-repeat center;
            background-size: 100%;
            width: 1.26rem;
            height: 0.45rem;
            margin-bottom: 0.1rem;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 0.32rem;
              color: #e76d01;
              line-height: 0.45rem;
              text-align: left;
            }
          }
        }
        &-body {
          display: flex;
          margin-bottom: 0.3rem;
          .link {
            display: none;
          }
          & > p,
          .mail {
            font-size: 0.24rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 0.33rem;
            padding: 0;
            margin: 0;
          }
          & > p::after {
            content: "：";
          }
        }
        &-foot {
          flex: 0 0 auto;
          width: 6.28rem;
          height: 3rem;
          border-radius: 0.06rem;
          position: relative;
          overflow: hidden;
          & > img {
            width: 100%;
            height: 100%;
          }
          .link {
            position: absolute;
            left: 0;
            bottom: 0.24rem;
            height: 0.5rem;
            line-height: 0.5rem;
            background: #e76d01;
            border-radius: 0 1rem 1rem 0;
            padding: 0 0.26rem;
            box-sizing: border-box;
            font-size: 0.26rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 750px) {
  .about {
    .section-vision {
      display: block;
      background: url("../../assets/about/vision.jpg") no-repeat center center;
      background-size: cover;
      min-width: 1190px;
      height: 500px;
    }
    .section-part1 {
      background: #fff;
      padding: 150px 0 120px;
      box-sizing: border-box;
      .part1 {
        display: flex;
        &-head {
          margin-bottom: 26px;
          .icon {
            background: url("../../assets/about/part1-0.png") no-repeat center;
            background-size: 100%;
            width: 162px;
            height: 45px;
            margin-bottom: 10px;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 32px;
              color: #e76d01;
              line-height: 32px;
            }
          }
        }
        &-body {
          & > p {
            font-family: PingFangSC-Light;
            font-size: 20px;
            color: #333333;
            line-height: 40px;
            text-align: left;
            &:not(:last-child) {
              margin-bottom: 50px;
            }
          }
        }
        &-foot {
          width: 545px;
          margin-left: 60px;
          flex: 0 0 auto;
          & > img {
            width: 100%;
            border-radius: 6px;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    .section-part2 {
      padding: 110px 0 92px;
      box-sizing: border-box;
      .part2 {
        &-body {
          display: flex;
          flex-direction: column;
          .item {
            width: 100%;
            display: flex;
            &:not(:last-child) {
              margin-bottom: 60px;
            }
            &-head {
              flex: 0 0 auto;
              width: 514px;
              height: 300px;
              border-radius: 6px;
              overflow: hidden;
              margin-right: 72px;
              & > img {
                width: 100%;
                height: 100%;
              }
            }
            &-body {
              display: flex;
              flex-direction: column;
              & > h1 {
                font-size: 32px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #e76d01;
                line-height: 45px;
                margin: 0 0 26px 0;
                text-align: left;
              }
              & > ul {
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 0;
                & > li {
                  display: flex;
                  width: 100%;
                  text-align: left;
                  align-items: flex-start;
                  &:not(:last-child) {
                    margin-bottom: 12px;
                  }
                  & > strong {
                    flex: 0 0 auto;
                    font-size: 24px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 40px;
                    width: 160px;
                  }
                  & > span {
                    font-size: 20px;
                    font-family: PingFangSC-Light, PingFang SC;
                    font-weight: 300;
                    color: #333333;
                    line-height: 40px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .section-part3 {
      background: #fff;
      padding: 120px 0 70px;
      box-sizing: border-box;
      .part3 {
        display: flex;
        flex-direction: row-reverse;
        &-head {
          margin-bottom: 26px;
          .icon {
            background: url("../../assets/about/part3-0.png") no-repeat center;
            background-size: 100%;
            width: 358px;
            height: 45px;
            margin-bottom: 10px;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 32px;
              color: #e76d01;
              line-height: 32px;
              text-align: left;
            }
          }
        }
        &-body {
          display: flex;
          flex-direction: column;
          .item {
            display: flex;
            flex-direction: column;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 24px;
              color: #333333;
              line-height: 48px;
              margin-bottom: 6px;
              text-align: left;
            }
            & > span {
              font-family: PingFangSC-Light;
              font-size: 20px;
              color: #333333;
              line-height: 40px;
              text-align: left;
            }
          }
        }
        &-foot {
          flex: 0 0 auto;
          width: 460px;
          height: 730px;
          margin-right: 146px;
          & > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .section-part4 {
      padding: 70px 0 120px;
      box-sizing: border-box;
      .part4 {
        display: flex;
        &-head {
          margin-bottom: 46px;
          .icon {
            background: url("../../assets/about/part3-0.png") no-repeat center;
            background-size: 100%;
            width: 358px;
            height: 45px;
            margin-bottom: 10px;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 32px;
              color: #e76d01;
              line-height: 45px;
              text-align: left;
            }
          }
        }
        &-body {
          display: flex;
          flex-direction: column;
          .item {
            display: flex;
            flex-direction: column;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 24px;
              color: #333333;
              line-height: 33px;
              text-align: left;
              margin-bottom: 6px;
            }
            & > span {
              font-family: PingFangSC-Light;
              font-size: 20px;
              color: #333333;
              line-height: 40px;
              text-align: left;
            }
          }
        }
        &-foot {
          flex: 0 0 auto;
          width: 445px;
          margin-left: 75px;
          & > img {
            width: 100%;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    .section-part5 {
      background: #fff;
      padding: 120px 0 140px;
      box-sizing: border-box;
      .part5 {
        display: flex;
        flex-direction: row-reverse;
        &-head {
          margin-bottom: 26px;
          .icon {
            background: url("../../assets/about/part5-0.png") no-repeat center;
            background-size: 100%;
            width: 126px;
            height: 45px;
            margin-bottom: 10px;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 32px;
              color: #e76d01;
              line-height: 45px;
              text-align: left;
            }
          }
        }
        &-body {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .link {
            width: 260px;
            background: #e76d01;
            border-radius: 0 100px 100px 0;
            height: 50px;
            line-height: 50px;
            font-family: PingFangSC-Semibold;
            font-size: 26px;
            color: #ffffff;
            padding: 0 26px;
            box-sizing: border-box;
            margin-bottom: 26px;
          }
          & > p {
            font-family: PingFangSC-Medium;
            font-size: 24px;
            color: #333333;
            line-height: 33px;
            margin: 0 0 6px 0;
          }
          .mail {
            font-family: PingFangSC-Light;
            font-size: 24px;
            color: #333333;
            line-height: 48px;
          }
        }
        &-foot {
          flex: 0 0 auto;
          width: 660px;
          margin-right: 84px;
          & > img {
            width: 100%;
            border-radius: 6px;
          }
          .link {
            display: none;
          }
        }
      }
    }
  }
}
</style>
